<template>
    <el-container>
      <el-main>
        <el-form :inline="true" :model="formSearch">
            <el-form-item label="用户名">
                <el-input v-model="formSearch.username" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item label="是否禁用">
                <el-select v-model="formSearch.status" placeholder="请选择">
                    <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleSearch">查询</el-button>
            </el-form-item>
        </el-form>
        <div style="margin-bottom: 10px;">
            <el-button type="danger" @click="handleDelSelectTLog">删除选中</el-button>
            <el-button @click="toggleSelection()">取消选择</el-button>
        </div>
        <el-table ref="multipleTable" :data="tableData" border stripe height="680" @selection-change="handleSelectionChange">
            <el-table-column
            type="selection"
            width="55">
            </el-table-column>
            <el-table-column prop="id" label="id">
            </el-table-column>
            <el-table-column prop="username" label="用户名">
            </el-table-column>
            <el-table-column prop="password" label="密码">
            </el-table-column>
            <el-table-column prop="nickname" label="昵称">
            </el-table-column>
            <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                {{ statusName(scope.row.status) }}
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="100">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button type="text" size="small" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="表单弹窗" :visible.sync="dialogVisible" width="30%">
            <el-form lable-width="80px">
            <el-form-item label="id">
                <el-input v-model="form.id" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item label="昵称">
                <el-input v-model="form.nickname"></el-input>
            </el-form-item>
            <el-select v-model="form.status" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
            </el-select>
            </el-form>
            <span slot="footer" class="dialo-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button @click="handleConfirm" type="primary">确 定</el-button>
            </span>
        </el-dialog>
      </el-main>
    <el-footer>
        <el-pagination
        background
        layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-size="formSearch.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="formSearch.total">
        </el-pagination>
    </el-footer>
    </el-container>
</template>
<script>
import axios from 'axios'
export default {
    name: 'YgList',
    components: {
    },
    data() {
      return {        
        tableData: [],
        formSearch: {
          username: '',
          status: 5,
          pageSize: 10,
          total: 0,
          currentPage: 0,
        },
        form: {
            rowEditing: 0,
            id: 0,
            username: '',
            password: '',
            nickname: '',
            status: '',
        },
        options1: [
            {value: 5, label: '全部'},
            {value: 0, label: '正常'},
            {value: 1, label: '禁用'},
        ],
        options: [
            {value: 0, label: '正常'},
            {value: 1, label: '禁用'},
        ],
        multipleSelection: [],
        dialogVisible: false,
      }
    },
    methods: {
        handleDelete(index) {
            var _this = this
            this.$confirm('确定删除该条数据吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            axios.post('/api/admin/delSelectUser', {ids: index.id+''})
            .then((response) => {
                if(response.data.code == 0) {
                _this.tableData.splice(index.$index, 1)
                _this.$message({
                    type: 'success',
                    message: response.data.msg
                })
                _this.getDefaultPage(_this.currentPage)
                } else {
                _this.$message({
                    type: 'error',
                    message: response.data.msg
                })
                }
            })
            }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            })
            })
        },
        handleDelSelectTLog() {
            if (this.multipleSelection.length == 0) {
                this.$message({
                    type: 'error',
                    message: '没有选中任何数据'
                })
                return
            }
            var _this = this
            this.$confirm('确定删除选中数据吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids = ''
                _this.multipleSelection.forEach(row => {
                    ids = ids + row.id + ','
                })
                ids = ids.substring(0, ids.length-1)
                
                axios.post('/api/admin/delSelectUser', {ids: ids})
                .then(response => {
                    if(response.data.code == 0) {
                        _this.$message({
                        type: 'success',
                        message: response.data.msg
                        })
                        _this.getDefaultPage()
                    } else {
                        _this.$message({
                        type: 'error',
                        message: response.data.msg
                        })
                    }
                })
            }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            })
            })
        },
        handleCurrentChange(val) { // 分页页数改变
            var valNum = parseInt(val) -1
            this.formSearch.currentPage = valNum
            var _this = this // valNum+'/'+this.pageSize
            axios.post('/api/admin/userList', {page: valNum, offset: this.formSearch.pageSize, username: this.formSearch.username, status: this.formSearch.status})
            .then(response => {
            if(response.data.code == 0) {
                _this.tableData = response.data.data
                _this.formSearch.total = response.data.total
            } else {
                _this.$message({
                type: 'error',
                message: response.data.msg
                })
            }
            })
        },
        handleSizeChange(val) { // 每页条数改变
            this.formSearch.pageSize = val
            this.getDefaultPage()
        },
        getDefaultPage(pageN) {
            if (!pageN) {
            pageN = 0
            }
            var _this = this
            axios.post('/api/admin/userList', {page: pageN, offset: this.formSearch.pageSize, username: this.formSearch.username, status: this.formSearch.status})
            .then(response => {
            // console.log(response.data)
            if(response.data.code == 0) {
                _this.tableData = response.data.data
                _this.formSearch.total = response.data.total
            } else {
                _this.$message({
                    type: 'error',
                    message: response.data.msg
                })
            }
            })
            .catch(function (error) {
            if(error.response.status == 404 && error.response.data.code == 99) {
                _this.$message({
                type: 'error',
                message: '您还未登录'
                })
                window.location.href = '/#/admin-login/'
            }
            })
        },
        handleSearch() {
            this.getDefaultPage()
        },
        toggleSelection(rows) {
            if (rows) {
            rows.forEach(row => {
                this.$refs.multipleTable.toggleRowSelection(row);
            });
            } else {
            this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleEdit(index) {
            this.dialogVisible = true
            this.form.id = index.id
            this.form.username = index.username
            this.form.password = index.password
            this.form.nickname = index.nickname
            this.form.status = index.status
            this.form.rowEditing = index
        },
        // 提交修改 
        handleConfirm() {
            var _this = this
            axios.post('/api/admin/editUser', {id: this.form.id, password: this.form.password, nickname: this.form.nickname, status: this.form.status})
            .then(response => {
                if(response.data.code == 0) {
                    _this.dialogVisible =false
                    var index = _this.form.rowEditing
                    index.password = this.form.password
                    index.nickname = this.form.nickname
                    index.status = this.form.status
                    _this.$message({
                        message: response.data.msg,
                        type: 'success'
                    });
                } else {
                    _this.$message({
                        message: response.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        statusName(sts) {
            sts = parseInt(sts)
            var stsName = '状态错误'
            this.options.forEach(item => {
            if(sts == item.value) {
                stsName = item.label
                return
            }
            });
            return stsName
        },
    },
    mounted () {
        document.title = '员工列表'
        this.$emit('getMessage', {mtitle: '员工列表', isLoginPage: false});
        this.getDefaultPage()
    }
  }
  </script>