<template>
    <el-container>
      <el-main>
        <el-form :model="formData" ref="systemForm" :rules="rule">
            <el-form-item label="每日限定时长(秒)" prop="limittime">
                <el-input-number v-model="formData.limittime" placeholder="每日限定时长(秒)" :step="60" :min="0" :max="10800" step-strictly></el-input-number>
            </el-form-item>
            <el-form-item label="每日普通事件限定次数" prop="limittimes">
                <el-input-number v-model="formData.limittimes" placeholder="每日普通事件限定次数" :step="1" :min="0" :max="20"></el-input-number>
            </el-form-item>
            <el-form-item label="密码(如不修改密码，请留空)" prop="password">
                <el-input v-model="formData.password" placeholder="(如不修改密码，请留空)" show-password></el-input>
            </el-form-item>
            <el-form-item label="重新输入密码(如不修改密码，请留空)" prop="password">
                <el-input v-model="formData.password1" placeholder="重新输入密码(如不修改密码，请留空)" show-password></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleChange">修改</el-button>
            </el-form-item>
        </el-form>
      </el-main>
    <el-footer>
    </el-footer>
    </el-container>
</template>
<script>
import axios from 'axios'

// import axios from 'axios'
export default {
    name: 'SystemSet',
    components: {
    },
    data() {
        return {
            formData: {
                id: 0,
                limittime: 0,
                limittimes: 0,
                password: '',
                password1: '',
            },
            rule: {
                password: [
                    {min: 4, max: 8, message: '长度在 4 到 8 个字符', trigger: 'blur'},
                    {
                        required: false,
                        pattern: /^[a-zA-Z0-9]+$/,
                        message: '密码仅支持数字、大小写英文字母',
                        trigger: 'blur'
                    }
                ],
                password1: [
                    {min: 4, max: 8, message: '长度在 4 到 8 个字符', trigger: 'blur'},
                    {
                        required: false,
                        pattern: /^[a-zA-Z0-9]+$/,
                        message: '密码仅支持数字、大小写英文字母',
                        trigger: 'blur'
                    }
                ],
                limittime: [
                    {required: true, message: '请输入限定时长', trigger: 'blur'},
                ],
                limittimes: [
                    {required: true, message: '请输入限定次数', trigger: 'blur'},
                ]
            },
        }
    },
    methods: {
        handleChange() {
            var _this = this
            this.$refs['systemForm'].validate((valid) => {
                if(valid) {
                    if(_this.formData.password != '' && _this.formData.password != _this.formData.password1) {
                        _this.$message({
                            type: 'error',
                            message: '两次输入密码不一致！'
                        })
                        return
                    }
                    axios.post('/api/admin/systemEdit', this.formData)
                    .then((response) => {
                        if(response.data.code == 0) {
                            _this.$message({
                                'type': 'success',
                                'message': response.data.msg
                            })
                        } else {
                            _this.$message({
                                'type': 'error',
                                'message': response.data.msg
                            })
                        }
                    })
                } else {
                    return false
                }
            })
        }
    },
    mounted () {
        document.title = '全局设置'
        this.$emit('getMessage', {mtitle: '全局设置', isLoginPage: false});
        var _this = this
        axios.get('/api/admin/systemSet')
        .then((response)=> {
            if(response.data.code == 0) {
                _this.formData = response.data.data
            } else {
                _this.$message({
                    type: 'error',
                    message: response.data.msg
                })
            }
        })
    }
  }
  </script>