<template>
  <el-container>
    <el-main>
      <h1>欢迎登录</h1>
    </el-main>
  </el-container>
</template>
<script>
export default {
  name: 'HelloWorld',
  mounted () {
      document.title = '管理首页'
      this.$emit('getMessage', {mtitle: '管理首页', isLoginPage: false});
  }
}
</script>