<template>
    <el-container>
      <el-main>
        <div style="margin-bottom: 10px;">
            <el-button type="danger" @click="handleDelSelectTLog">删除选中</el-button>
            <el-button @click="toggleSelection()">取消选择</el-button>
        </div>
        <el-table ref="multipleTable" :data="tableData" border stripe height="680" @selection-change="handleSelectionChange">
            <el-table-column
            type="selection"
            width="55">
            </el-table-column>
            <el-table-column prop="id" label="id">
            </el-table-column>
            <el-table-column prop="name" label="名称">
            </el-table-column>
            <el-table-column prop="limittime" label="限制时长(秒)">
            </el-table-column>
            <el-table-column prop="limittimes" label="每日限制次数">
            </el-table-column>
            <el-table-column prop="type" label="类别">
                <template slot-scope="scope">
                {{ statusName(scope.row.type) }}
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="100">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button type="text" size="small" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="表单弹窗" :visible.sync="dialogVisible" width="30%">
            <el-form lable-width="80px">
            <el-form-item label="id">
                <el-input v-model="form.id" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="名称">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="限制时长(秒)">
                <el-input-number v-model="form.limittime" :step="60" :disabled="form.type==2" step-strictly :min="0" :max="86400"></el-input-number>
            </el-form-item>
            <el-form-item label="每日限制次数">
                <el-input-number v-model="form.limittimes" :step="1" :disabled="form.type==2" step-strictly :min="0" :max="20"></el-input-number>
            </el-form-item>
            <el-select v-model="form.type" placeholder="请选择" :disabled="true">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
            </el-select>
            </el-form>
            <span slot="footer" class="dialo-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button @click="handleConfirm" type="primary">确 定</el-button>
            </span>
        </el-dialog>
      </el-main>
    </el-container>
</template>
<script>
import axios from 'axios'
export default {
    name: 'ActionList',
    components: {
    },
    data() {
      return {        
        tableData: [],
        form: {
            rowEditing: 0,
            id: 0,
            name: '',
            type: 0,
            limittime: 0,
            limittimes: 0,
        },
        options: [
            {value: 1, label: '普通事件'},
            {value: 2, label: '打卡事件'},
        ],
        multipleSelection: [],
        dialogVisible: false,
      }
    },
    methods: {
        handleDelete(index) {
            var _this = this
            this.$confirm('确定删除该条数据吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            axios.post('/api/admin/delSelectAction', {ids: index.id+''})
            .then((response) => {
                if(response.data.code == 0) {
                _this.tableData.splice(index.$index, 1)
                _this.$message({
                    type: 'success',
                    message: response.data.msg
                })
                _this.getDefaultPage()
                } else {
                _this.$message({
                    type: 'error',
                    message: response.data.msg
                })
                }
            })
            }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            })
            })
        },
        handleDelSelectTLog() {
            if (this.multipleSelection.length == 0) {
                this.$message({
                    type: 'error',
                    message: '没有选中任何数据'
                })
                return
            }
            var _this = this
            this.$confirm('确定删除选中数据吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids = ''
                _this.multipleSelection.forEach(row => {
                    ids = ids + row.id + ','
                })
                ids = ids.substring(0, ids.length-1)
                
                axios.post('/api/admin/delSelectAction', {ids: ids})
                .then(response => {
                    if(response.data.code == 0) {
                        _this.$message({
                        type: 'success',
                        message: response.data.msg
                        })
                        _this.getDefaultPage()
                    } else {
                        _this.$message({
                        type: 'error',
                        message: response.data.msg
                        })
                    }
                })
            }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            })
            })
        },
        getDefaultPage() {
            var _this = this
            axios.post('/api/admin/actionList', {type: 5})
            .then(response => {
            // console.log(response.data)
            if(response.data.code == 0) {
                _this.tableData = response.data.data
            } else {
                _this.$message({
                    type: 'error',
                    message: response.data.msg
                })
            }
            })
            .catch(function (error) {
                if(error.response.status == 404 && error.response.data.code == 99) {
                    _this.$message({
                    type: 'error',
                    message: '您还未登录'
                    })
                    window.location.href = '/#/admin-login/'
                }
            })
        },
        toggleSelection(rows) {
            if (rows) {
            rows.forEach(row => {
                this.$refs.multipleTable.toggleRowSelection(row);
            });
            } else {
            this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleEdit(index) {
            this.dialogVisible = true
            this.form.id = index.id
            this.form.name = index.name
            this.form.limittime = index.limittime
            this.form.limittimes = index.limittimes
            this.form.type = index.type
            this.form.rowEditing = index
        },
        // 提交修改 
        handleConfirm() {
            var _this = this
            axios.post('/api/admin/editAction', {id: this.form.id, name: this.form.name, limittime: this.form.limittime, limittimes: this.form.limittimes})
            .then(response => {
                if(response.data.code == 0) {
                    _this.dialogVisible =false
                    var index = _this.form.rowEditing
                    index.name = this.form.name
                    index.limittime = this.form.limittime
                    index.limittimes = this.form.limittimes
                    _this.$message({
                        message: response.data.msg,
                        type: 'success'
                    });
                } else {
                    _this.$message({
                        message: response.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        statusName(sts) {
            sts = parseInt(sts)
            var stsName = '状态错误'
            this.options.forEach(item => {
            if(sts == item.value) {
                stsName = item.label
                return
            }
            });
            return stsName
        },
    },
    mounted () {
        document.title = '事件列表'
        this.$emit('getMessage', {mtitle: '事件列表', isLoginPage: false});
        this.getDefaultPage()
    }
  }
  </script>