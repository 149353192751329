<template>
    <el-container>
      <el-main>
        <el-form :model="formData" :rules="rule" ref="addActionForm">
            <el-form-item label="名称" prop="name">
                <el-input v-model="formData.name" placeholder="名称(2-8个字符)"></el-input>
            </el-form-item>
            <el-form-item label="类别" prop="type">
                <el-select v-model="formData.type" placeholder="请选择">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="限制时长(秒)" prop="limittime">
                <el-input-number v-model="formData.limittime" placeholder="限制时长(秒)" :step="60" :disabled="formData.type==2" step-strictly :min="0" :max="86400"></el-input-number>
            </el-form-item>
            <el-form-item label="每日限定次数" prop="limittime">
                <el-input-number v-model="formData.limittimes" placeholder="每日限定次数" :step="1" :disabled="formData.type==2" step-strictly :min="0" :max="20"></el-input-number>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleAdd">添加</el-button>
                <el-button @click="resetForm('addActionForm')">重置</el-button>
            </el-form-item>
        </el-form>
      </el-main>
    <el-footer>
    </el-footer>
    </el-container>
</template>
<script>
import axios from 'axios'

// import axios from 'axios'
export default {
    name: 'AddAction',
    components: {
    },
    data() {
        return {
            formData: {
                name: '',
                type: 1,
                limittime: 0,
                limittimes: 0
            },
            options: [
                {value: 1, label: '普通事件'},
                {value: 2, label: '打卡事件'},
            ],
            rule: {
                name: [
                    {required: true, message: '请输入事件名称', trigger: 'blur'},
                    {min: 2, max: 8, message: '长度在 2 到 8 个字符', trigger: 'blur'},
                    {
                        required: true,
                        pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]+$/,
                        message: '昵称不支持特殊字符',
                        trigger: 'blur'
                    }
                ],
                type: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                ],
                limittime: [
                    {required: true, message: '请输入限定时长', trigger: 'blur'},
                ],
                limittimes: [
                    {required: true, message: '请输入每日限定次数', trigger: 'blur'},
                ]
            },
        }
    },
    methods: {
        handleAdd() {
            var _this = this
            this.$refs['addActionForm'].validate((valid) => {
                if(valid) {
                    // console.log('提交')
                    axios.post('/api/admin/addAction', {name: _this.formData.name, type: _this.formData.type, limittime: _this.formData.limittime, limittimes: _this.formData.limittimes})
                    .then((response) => {
                        if(response.data.code == 0) {
                            _this.$message({
                                'type': 'success',
                                'message': response.data.msg
                            })
                            _this.resetForm('addActionForm')
                        } else {
                            _this.$message({
                                'type': 'error',
                                'message': response.data.msg
                            })
                        }
                    })
                } else {
                    return false
                }
            })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    },
    mounted () {
        document.title = '添加事件'
        this.$emit('getMessage', {mtitle: '添加事件', isLoginPage: false});
    }
  }
  </script>