<template>
    <el-container>
      <el-main>
        <el-form :inline="true" :model="formSearch" ref="formSearch">
            <el-form-item label="用户名" prop="username" >
                <el-input v-model="formSearch.username" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item label="是否超时" prop="status">
                <el-select v-model="formSearch.status" placeholder="请选择">
                    <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="日期" prop="dateRange">
              <el-date-picker
                v-model="formSearch.dateRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleSearch">查询</el-button>
                <el-button @click="resetForm('formSearch')">重置</el-button>
            </el-form-item>
        </el-form>
        <div style="margin-bottom: 10px;">
            <el-button type="danger" @click="handleDelSelectTLog">删除选中</el-button>
            <el-button @click="toggleSelection()">取消选择</el-button>
        </div>
        <el-table ref="multipleTable" :data="tableData" border stripe height="680" @selection-change="handleSelectionChange">
            <el-table-column
            type="selection"
            width="55">
            </el-table-column>
            <el-table-column prop="id" label="id">
            </el-table-column>
            <el-table-column prop="action_name" label="事件名称">
            </el-table-column>
            <el-table-column prop="limittime_m" label="限定时间(分)">
            </el-table-column>
            <el-table-column prop="starttime_str" label="开始时间">
            </el-table-column>
            <el-table-column prop="endtime_str" label="结束时间">
            </el-table-column>
            <el-table-column prop="usetime_m" label="实际用时(分)">
            </el-table-column>
            <el-table-column prop="overtime_m" label="超时(分)">
            </el-table-column>
            <el-table-column prop="username" label="用户名">
            </el-table-column>
            <el-table-column prop="nickname" label="用户昵称">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="100">
                <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
      </el-main>
    <el-footer>
        <el-pagination
        background
        layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-size="formSearch.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="formSearch.total">
        </el-pagination>
    </el-footer>
    </el-container>
</template>
<script>
import axios from 'axios'
export default {
    name: 'LogTime',
    components: {
    },
    data() {
      return {        
        tableData: [],
        formSearch: {
          username: '',
          status: 5,
          dateRange: [],
          pageSize: 10,
          total: 0,
          currentPage: 0,
        },
        options: [
          {value: 1, label: '未超时'},
          {value: 2, label: '已超时'},
        ],
        options1: [
            {value: 5, label: '全部'},
            {value: 1, label: '未超时'},
            {value: 2, label: '已超时'},
        ],
        multipleSelection: [],
      }
    },
    methods: {
      handleDelete(index) {
        var _this = this
        this.$confirm('确定删除该条数据吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post('/api/admin/delSelectTLog', {ids: index.id+''})
          .then((response) => {
            if(response.data.code == 0) {
              _this.tableData.splice(index.$index, 1)
              _this.$message({
                type: 'success',
                message: response.data.msg
              })
              _this.getDefaultPage(_this.currentPage)
            } else {
              _this.$message({
                type: 'error',
                message: response.data.msg
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      handleDelSelectTLog() {
        if (this.multipleSelection.length == 0) {
            this.$message({
                type: 'error',
                message: '没有选中任何数据'
            })
            return
        }
        var _this = this
        this.$confirm('确定删除选中数据吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            var ids = ''
            _this.multipleSelection.forEach(row => {
                ids = ids + row.id + ','
            })
            ids = ids.substring(0, ids.length-1)
             
            axios.post('/api/admin/delSelectTLog', {ids: ids})
            .then(response => {
                if(response.data.code == 0) {
                    _this.$message({
                    type: 'success',
                    message: response.data.msg
                    })
                    _this.getDefaultPage()
                } else {
                    _this.$message({
                    type: 'error',
                    message: response.data.msg
                    })
                }
            })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      handleCurrentChange(val) { // 分页页数改变
        var valNum = parseInt(val) -1
        this.formSearch.currentPage = valNum

        var _this = this // 
        axios.post('/api/admin/qdLog', {page: valNum, offset: this.formSearch.pageSize, username: this.formSearch.username, status: this.formSearch.status, dateRange: this.dateRangeStr})
        .then(response => {
          if(response.data.code == 0) {
            _this.tableData = response.data.data
            _this.formSearch.total = response.data.total
          } else {
            _this.$message({
              type: 'error',
              message: response.data.msg
            })
          }
        })
      },
      handleSizeChange(val) { // 每页条数改变
        this.formSearch.pageSize = val
        this.getDefaultPage()
      },
      getDefaultPage(pageN) {
        if (!pageN) {
          pageN = 0
        }

        var _this = this
        axios.post('/api/admin/qdLog', {page: pageN, offset: this.formSearch.pageSize, username: this.formSearch.username, status: this.formSearch.status, dateRange: this.dateRangeStr})
        .then(response => {
          // console.log(response.data)
          if(response.data.code == 0) {
            _this.tableData = response.data.data
            _this.formSearch.total = response.data.total
          } else {
            _this.$message({
                type: 'error',
                message: response.data.msg
            })
          }
        })
        .catch(function (error) {
          if(error.response.status == 404 && error.response.data.code == 99) {
            _this.$message({
              type: 'error',
              message: '您还未登录'
            })
            window.location.href = '/#/admin-login/'
          }
        })
      },
      handleSearch() {
        this.getDefaultPage()
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    },
    mounted () {
      document.title = '签到记录（按次）'
      this.$emit('getMessage', {mtitle: '签到记录（按次）', isLoginPage: false});
      this.getDefaultPage()
    },
    computed: {
      dateRangeStr() {
        if(this.formSearch.dateRange.length == 2) {
          var dateRange = this.formSearch.dateRange
          var dateStr1 = dateRange[0].getFullYear()+'-'+(dateRange[0].getMonth()+1)+'-'+(dateRange[0].getDate())
          var dateStr2 = dateRange[1].getFullYear()+'-'+(dateRange[1].getMonth()+1)+'-'+(dateRange[1].getDate())
          return dateStr1+':'+dateStr2
        } else {
          return ''
        }
      }
    }
  }
  </script>