<template>
    <el-container>
      <el-main>
        <el-form :model="formData" :rules="rule" ref="addUserForm">
            <el-form-item label="用户名" prop="username">
                <el-input v-model="formData.username" placeholder="用户名(4-8个字符)"></el-input>
            </el-form-item>
            <el-form-item label="昵称" prop="nickname">
                <el-input v-model="formData.nickname" placeholder="昵称(2-8个字符)"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input v-model="formData.password" placeholder="密码(4-8个字符)"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleAdd">添加</el-button>
                <el-button @click="resetForm('addUserForm')">重置</el-button>
            </el-form-item>
        </el-form>
      </el-main>
    <el-footer>
    </el-footer>
    </el-container>
</template>
<script>
import axios from 'axios'

// import axios from 'axios'
export default {
    name: 'AddYg',
    components: {
    },
    data() {
        return {
            formData: {
                username: '',
                nickname: '',
                password: '123456'
            },
            rule: {
                username: [
                    {required: true, message: '请输入用户名', trigger: 'blur'},
                    {min: 4, max: 8, message: '长度在 4 到 8 个字符', trigger: 'blur'},
                    {
                        required: true,
                        pattern: /^[a-z0-9]+$/,
                        message: '姓名仅支持小写英文字母和数字',
                        trigger: 'blur'
                    }
                ],
                nickname: [
                    {required: true, message: '请输入昵称', trigger: 'blur'},
                    {min: 2, max: 8, message: '长度在 2 到 8 个字符', trigger: 'blur'},
                    {
                        required: true,
                        pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]+$/,
                        message: '昵称不支持特殊字符',
                        trigger: 'blur'
                    }
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                    {min: 4, max: 8, message: '长度在 4 到 8 个字符', trigger: 'blur'},
                    {
                        required: true,
                        pattern: /^[a-zA-Z0-9]+$/,
                        message: '密码仅支持数字、大小写英文字母',
                        trigger: 'blur'
                    }
                ]
            },
        }
    },
    methods: {
        handleAdd() {
            var _this = this
            this.$refs['addUserForm'].validate((valid) => {
                if(valid) {
                    // console.log('提交')
                    axios.post('/api/admin/addUser', {username: _this.formData.username, nickname: _this.formData.nickname, password: _this.formData.password})
                    .then((response) => {
                        if(response.data.code == 0) {
                            _this.$message({
                                'type': 'success',
                                'message': response.data.msg
                            })
                            _this.resetForm('addUserForm')
                        } else {
                            _this.$message({
                                'type': 'error',
                                'message': response.data.msg
                            })
                        }
                    })
                } else {
                    return false
                }
            })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    },
    mounted () {
        document.title = '添加员工'
        this.$emit('getMessage', {mtitle: '添加员工', isLoginPage: false});
    }
  }
  </script>