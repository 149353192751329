import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import VueRouter from "vue-router"
import HelloWorld from '@/components/HelloWorld'
import LogTime from "@/components/LogTime"
import LogDay from '@/components/LogDay'
import Daka from '@/components/Daka'
import YgList from '@/components/YgList'
import AddYg from '@/components/AddYg'
import ActionList from '@/components/ActionList'
import AddAction from '@/components/AddAction'
import SystemSet from '@/components/SystemSet'
import LoginPage from '@/components/LoginPage'

Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: HelloWorld,
    name: "HelloWorld",
  },
  {
    path: "/log-time/",
    component: LogTime,
    name: "LogTime",
  },
  {
    path: "/log-day/",
    component: LogDay,
    name: "LogDay",
  },
  {
    path: "/da-ka/",
    component: Daka,
    name: "Daka",
  },
  {
    path: "/yg-list/",
    component: YgList,
    name: "YgList",
  },
  {
    path: "/add-yg/",
    component: AddYg,
    name: "AddYg",
  },
  {
    path: "/action-list/",
    component: ActionList,
    name: "ActionList",
  },
  {
    path: "/add-action/",
    component: AddAction,
    name: "AddAction",
  },
  {
    path: "/system-set/",
    component: SystemSet,
    name: "SystemSet",
  },
  {
    path: "/admin-login/",
    component: LoginPage,
    name: "LoginPage",
  },
]

const router = new VueRouter({
  routes // （缩写）相当于 routes: routes
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
